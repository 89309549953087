#uuidList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

#uuidList > p {
    font-family: 'Fira Code';
    text-align: center;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    color: white;
    border: 1px solid lightskyblue;
    user-select: none;
    cursor: default;
}

#uuidList > p.clicked {
    filter: brightness(0.5);
    color: gray;
    border-style: dashed;
}

#uuidList:focus {
    outline: none;
}