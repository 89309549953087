#spongeContainer {
    margin: 20px;
}

.textinput {
    background-color: var(--background-color-darker);
    border-radius: 5px;
    border: 0px;
    resize: none;
    color: white;
    font-family: 'Fira Code';
    font-size: 15px;
}

#spongeInput {
    height: 200px;
    width: calc(100% - 100px);
    padding: 50px;
}

#spongeOutput {
    display: flex;
    margin: 50px 0px 50px 50px;
    font-family: 'Fira Code';
    width: calc(100% - 100px);
    float: left;
    cursor: pointer;
}