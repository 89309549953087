@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

h1 {
    font-weight: bold;
    letter-spacing: -1px;
    font-size: 30px;
}

h2 {
    font-weight: 400;
    letter-spacing: -0.5px;
}

section {
    display: grid;
    height: 60vh;
    width: 100vw;
}

@keyframes becomeVisible {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#section1 {
    height: calc(100vh - (100vw / 3) - 10vh);
    width: 90%;
    padding-left: 10%;
    padding-top: 10vh;
    grid-template-rows: calc(2 * var(--title-font-size)) 6rem;
}

#main-header {
    color: var(--title-color);
    font-size: var(--title-font-size);
    text-align: left;
    letter-spacing: -2px;
    height: 30%;
}

#main-subtitles {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2rem 2rem 2rem;
    align-items: left;
    color: var(--subtitle-color);
    letter-spacing: -1.5px;
}

#main-subtitles>h2 {
    opacity: 0;
    text-align: left;
    white-space: nowrap;
    animation: 1s ease-in-out 1 becomeVisible forwards;
}

#main-subtitles>h2:nth-child(1) {
    animation-delay: 1s;
}

#main-subtitles>h2:nth-child(2) {
    animation-delay: 2s;
}

#main-subtitles>h2:nth-child(3) {
    animation-delay: 3s;
}

#location-list {
    position: absolute;
    top: 25px;
    left: calc(100% - 25px);
    transform: translate(-100%, 0%);
    color: var(--subtitle-color);
    text-align: right;
    white-space: nowrap;
}


#location-list>.old-location {
    filter: brightness(.35);
}

.spacer {
    aspect-ratio: 3;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0px;
    padding: 0px;
}

#guid-section>.clicked {
    /* filter: brightness(50%); */
    /* background-color: var(--background-color-darker); */
    color: gray;
    border: none;
}

#jsonoutput .json-key {
    color: rgba(122, 161, 247, 255);
}

#jsonoutput .json-prop-string {
    color: rgba(152, 206, 105, 255);
}

#jsonoutput .json-prop-number {
    color: rgba(246, 152, 98, 255);
}

#jsonoutput .json-prop-boolean {
    color: rgba(246, 152, 98, 255);
}

#jsonoutput .json-prop-null {
    color: rgba(246, 152, 98, 255);
}

.textinput {
    background-color: var(--background-color-darker);
    border-radius: 5px;
    border: 0px;
    resize: none;
    color: white;
    font-family: 'Fira Code';
    font-size: 15px;
}

.textinput:focus {
    outline: none;
}

#jsoninput {
    height: 20vh;
    width: calc(100% - 100px);
    padding: 50px;
}

#referenceinput {
    margin: 50px;
    padding: 15px;
    width: calc(100% - 130px);
}

.display-linebreak {
    white-space: pre-line;
}

#keyslist {
    margin: 0px;
    font-family: 'Fira Code';
    font-size: 15px;
}

#jsonoutput {
    display: flex;
    width: 100%;
}

#jsonoutput > pre {
    margin: 0px 0px 50px 50px;
    font-family: 'Fira Code';
    width: 50%;
    float: left
}