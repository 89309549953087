#colourContainer {
    width: 100vw;
    height: 100vh;
    background-color: red;
}

.overlayText {
    padding: 5px;
    margin: 7px;
    color: black;
    background-color: white;
    display: inline-block;
    border-radius: 5px;
    opacity: 0.7;
}