@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  --background-color: rgb(20, 20, 35);
  --background-color-darker: rgb(10, 10, 20);
  --title-color: rgb(255, 255, 255);
  --subtitle-color: rgb(120, 120, 160);
  --foreground-color: rgb(200, 200, 200);
  --title-font-size: 80px;
  --section2-background-color: rgb(198, 35, 104);
  --section2-card-color: rgb(160, 0, 85);
  --section3-background-color: rgb(39, 42, 148);
  --section4-background-color: rgb(0, 2, 81);
  --section5-background-color: rgb(0, 0, 0);

  font-family: 'Roboto', sans-serif;
}

body {
  background-color: var(--background-color);
  color: var(--foreground-color);
  width: 100vw;
  margin: 0;
}

.centred {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}